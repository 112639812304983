import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { pipesToSpan } from "../util/common";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import ParagraphFaq from "../components/paragraphs/faq";
import ParagraphHeroV4 from "../components/paragraphs/hero-v4";
import ParagraphZebra from "../components/paragraphs/zebra1";
import BlockContentLogos from "../components/blocks/content-logos2";
import BlockContentLogosSlider from "../components/blocks/content-logos-slider";
import ParagraphHubspot from "../components/paragraphs/hubspot3";
import HeaderMini from "../components/header-mini";
import BlockSlider from "../components/blocks/slider";
import TryOut from "../components/buttons/tryout";

function landingUseCase24({ data: { node }, path }) {

  const {
    heroImage,
    metatag,
    relationships,
    terms,
    formCardTitle,
    formCardDescription,
    tagsTitle,
    tags
  } = node;

  const {
    OGImage,
    ctBlock,
    faq,
    hero,
    crouselLogos,
    heroImageData,
    hubspot,
    integrationLogos,
    zebra,
  } = relationships;

  // Helper function to chunk the array into 3 rows
  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const tagsRows = chunkArray(tags, Math.ceil(tags.length / 3));

  const seo = getMetatags(metatag, OGImage?.localFile?.publicURL);

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow",
  });

  return (
    <Layout showCta={false} showMenus={false} showFooter={false} HeaderComp={HeaderMini}>
      <Seo {...seo} />

      <section className="bg-top bg-no-repeat  bg-contain bg-[#F9F8F7] 	">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-0 pt-[90px] md:pt-[150px] md:pb-[50px]">
          <ParagraphHeroV4
            data={hero}
            context="frontpage"
            className="pb-[44px]"
          >
            <div className="text-[#727C8D] text-sm leading-[14px] text-center">
              {terms}
            </div>
          </ParagraphHeroV4>

          <div className="w-fit m-auto ">
            <div className=" text-center">
              <img
                src={heroImageData.localFile?.publicURL}
                alt={`${heroImage?.alt}`}
                className="w-[703px]"
              />
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-[#F9F8F7] pb-[60px]">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-0 ">
          <BlockContentLogosSlider
            data={crouselLogos}
            className="--my-10 --md:mt-[70px] md:mb-[10px] space-y-5"
            context="v2"
          />
        </div>
      </section>

      <section className=" py-8 lg:pt-[120px] lg:pb-[80px] ">
        <div className="container mx-auto  space-y-4 lg:space-y-[100px] ">
          <h2
            className="text-[#3D434C] text-[32px] md:text-[60px] leading-[40px] md:leading-[72px] tracking-[-0.03em] text-left"
            dangerouslySetInnerHTML={{ __html: zebra.title }}
          />
          <ParagraphZebra
            data={zebra}
            className=" md:space-y-20"
            classNameCards="--space-y-4 --lg:space-y-[100px] "
          />
        </div>
      </section>
     
      <section className="py-8 lg:pt-[120px] lg:pb-[80px] bg-[#F9F8F7]">
        <div className="container mx-auto  space-y-4 lg:space-y-[50px] ">
        <h2
            className="text-[#3D434C] text-[32px] md:text-[60px] leading-[72px] md:leading-[78px] tracking-[-0.03em] text-left"
            dangerouslySetInnerHTML={{ __html: tagsTitle }}
          />
          <div className="space-y-5 ">
            {tagsRows.map((row,rowIndex) => (
              <div key={rowIndex} className="--flex --flex-row --justify-between --items-start --gap-x-[15px] overflow-hidden ">
                <BlockSlider  data={{
                  id:'slider' + rowIndex, 
                  step: (rowIndex + 1 ) * 0.25,
                  items:row.map((tag, tagIndex) => (
                  <div className="shrink-0	mr-5 h-[58px] px-[30px] py-[15px] bg-[#e7ecff] rounded-[40px] justify-center items-center gap-2.5 hover:bg-[#6E8EFF] text-[#315eff] hover:text-white" key={tagIndex}>
                    <div className=" text-xl font-normal --font-['Inter'] leading-7 whitespace-nowrap  ">{tag}</div>
                  </div>
                ))}} />
                
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-[#282D33] ">
        <div className="container mx-auto pb-12 p-6 md:p-[100px]">
          <div className="flex flex-col lg:flex-row items-end lg:justify-between px-6 md:px-[50px] gap-10">
            <div
              className="text-[40px] md:text-[60px] font-medium leading-[48px] md:leading-[72px] tracking-[-0.03em]  text-left text-white "
              dangerouslySetInnerHTML={{ __html: ctBlock?.title }}
            />
            <div className="">
              {/* <Link
                to={ctBlock?.cta?.uri}
                className="text-base rounded-lg bg-[#325FFF] py-3 px-12 font-medium text-white	hover:text-[#325FFF] hover:bg-white duration-300  boottom_cta_demo block w-max"
              >
                {ctBlock?.cta?.title}
              </Link> */}
              <TryOut
                title={ctBlock?.cta?.title}
                className="text-base  font-medium py-3 px-[30px] rounded-lg leading-[20px] whitespace-nowrap dark:hidden bg-[#325FFF] hover:bg-[#1B4DFF]  text-white border border-[#4A72FF] hover:border-[#3D434C88] demo_account try_it_free_hero "
                check={false}
              />
            </div>
          </div>
        </div>
      </section>


      <section className="py-8 md:py-[70px] bg-[#F9F8F7]">
        <div className="max-w-screen-xl mx-auto px-6 lg:px-0">
          <BlockContentLogos
            data={integrationLogos}
            context="section"
            className="space-y-8 md:space-y-[50px]"
          />
        </div>
      </section>

      <section className="py-4 px-4 md:px-0 md:py-[70px]">
        <div className="container mx-auto px-6 lg:px-0 bg-[#F9F8F7]">
          <ParagraphFaq data={faq} context="v2" />
        </div>
      </section>

      <section className="bg-[#282D33]" id="form">
        <div className="container relative md:h-[104px]"></div>

        <ParagraphHubspot data={hubspot}>
          <div className="--mt-8 md:mt-0  space-y-[34px]">
            <SectionCards.Title
              title={formCardTitle}
              className="text-[40px] md:text-[60px] font-regular leading-[48px] md:leading-[72px] tracking-[-0.03em] text-white"
              secondClassName="text-[50px] font-semibold leading-[55px] tracking-[-0.02em] text-transparent bg-clip-text bg-gradient-to-r from-[#FFFFFF] via-[#A4B8FF] to-[#FFFFFF]"
            />

            <div className="text-xl font-regular leading-[28px] tracking-[-0.01em] text-white">
              {formCardDescription}
            </div>
          </div>
        </ParagraphHubspot>
      </section>


      
    </Layout>
  );
}

const SectionCards = ({
  title,
  description = "",
  items,
  Card,
  bodyClass = "",
  cardClass = "",
}) => {
  return (
    <section className="py-12">
      <div className="container mx-auto">
        {/* <h3>{title}</h3> */}
        <SectionCards.Title title={title} />
        <div>{description}</div>
        <div className={bodyClass}>
          {items.map((item, index) => (
            <Card {...item} key={index} className={cardClass} />
          ))}
        </div>
      </div>
    </section>
  );
};

SectionCards.Title = ({
  title,
  className,
  secondClassName = "text-blue",
  ...props
}) => {
  const t = pipesToSpan(title, secondClassName);
  const t1 = t.replace("  ", "<br/>");
  return (
    <h2
      className={className}
      dangerouslySetInnerHTML={{ __html: t1 }}
      {...props}
    />
  );
};

export default landingUseCase24;

export const query = graphql`
  query lpUseCase24($id: String!) {
    node: nodeUseCaseLp2024(id: { eq: $id }) {
      id
      title
      heroImage: field_hero_image {
        alt
      }
      terms: field_description

      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      formCardTitle: field_title
      formCardDescription: field_sub_title_4
      tagsTitle: field_title2
      tags: field_measuring_terms
      relationships {
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
        hero: field_hero {
          ...ParagraphHeroV2
        }

        heroImageData: field_hero_image {
          localFile {
            publicURL
          }
        }

        crouselLogos: field_carousel_logos {
          ...BlockContentLogos
        }

        integrationLogos: field_integration_logos {
          ...BlockContentLogos
        }

        zebra: field_cards_section {
          ...ParagraphZebra
        }

        faq: field_faq {
          ...ParagraphFaq
        }

        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }

        ctBlock: field_content_block {
          ...ParagraphGenericBlock
        }
      }
    }
  }
`;
