import React, { useEffect } from "react";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

const BlockSlider = ({ data, context, ...props }) => {
  const { items, id, step } = data;
  const { className } = props;

  return (
    <div className={className}>
      <LogoSlider2 items={items} id={id} step={step} />
    </div>
  );
};

const LogoSlider2 = ({ items, id, step }) => {
  useEffect(() => {
    const slider = document.getElementById(id);
    console.log(slider)
    let isPaused = false;

    slider.addEventListener("mouseenter", () => {
      //console.log("mouseenter");
      isPaused = true; // Pause animation
      //setIsPaused(true)
    });

    slider.addEventListener("mouseleave", () => {
      //console.log("mouseleave");
      isPaused = false; // Resume animation
      animateSlider()
    });

    const sliderItems = Array.from(slider.children);

    let thershold = 0;
    sliderItems.forEach((item) => {
      thershold += item.offsetWidth;
    });

    // Initialize position for the slider
    let sliderPosition = 0;

    // Duplicate items to create an infinite loop effect
    const ine = slider.innerHTML;
    slider.innerHTML += ine; // Duplicate content
    slider.innerHTML += ine; // Duplicate content

    // Function to animate the slider
    function animateSlider() {
      //console.log('isPaused', isPaused)
      if (!isPaused) {
        sliderPosition -= step;
        if (Math.abs(sliderPosition) >= thershold + 20 * sliderItems.length) {
          sliderPosition = 0; // Reset position for the infinite effect
        }
        //console.log('AnimationSlider2', sliderPosition)
        slider.style.transform = `translateX(${sliderPosition}px)`;
        requestAnimationFrame(animateSlider); // Call function on each frame
      }
    }

    animateSlider();
  }, [id, step]);

  return (
    <div
      className=" mx-0 flex relative "
      id={id}
    >
      {items}
    </div>
  );
};

export default BlockSlider;
