import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import logo from "../images/logo-0.svg";
import logoDark from "../images/Logo-horizontal-white.svg";
// import menuImg from "../images/menu.svg";
// import closeImg from "../images/close-x.svg";
import arrow from "../images/arrow.svg";
import useHeader from "../hooks/useHeader";
import useMegaLinks from "../hooks/useMegaLinks";
import NavigationMega from "./navigation-mega";
import platformIcon from "../images/platform.svg";
import platformArrow from "../images/arrow_right.svg";
import TryOut from "./buttons/tryout";

const HeaderMini = ({
  dark = false,
}) => {
  //const [isOpen, setIsOpen] = useState(false);
  //const [subMenuId, setSubMenuId] = useState(0);

//   const toggleSubMenu = (id) => {
//     if (id === subMenuId) {
//       setSubMenuId(0);
//     } else {
//       setSubMenuId(id);
//     }
//   };

  const { cta/*, signIn */} = useHeader();
  const { links } = useMegaLinks();

  let cClases = "";
  links.push({
    label: "Pricing",
    url: "/pricing/",
    status: true,
    description: "",
    groups: [],
  });

  const [isIntiPosition, setIsIntiPosition] = useState(true);

  const handleScroll = (e) => {
    setIsIntiPosition(!(window.scrollY > 0));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const transparent = /*isFrontPage &&*/ isIntiPosition;

  // return (
  //   <header className="">
  //     this is my header
  //   </header>
  // )
  return (
    <header
      className={
        "dark:border-none fixed print:static lg:top-0 lg:left-0 w-full  z-20  dark:text-white dark:bg-[#ffffff02] " +
        (transparent
          ? "bg-[#F9F8F7] "
          : " --border-b border-[#E9EFF688] bg-[#F9F8F7] lg:bg-[#F9F8F7D8] ")
      }
      style={{
        backdropFilter: dark ? "blur(6.5px)" : transparent ? "" : "blur(8px)",
      }}
    >
      <div
        className={
          "xl:container px-4 xl:px-0 2xl:px-4 flex flex-row justify-between items-center h-[70px] " +
          cClases
        }
      >
        <div className="--w-1/2 lg:w-auto lg:z-30 lg:flex flex-row items-center">
          <a href="/" className="dark:hidden w-[130px] h-[28px]">
            <img src={logo} alt="sightfull" className="inline " />
          </a>
          <a href="/" className="hidden dark:block">
            <img src={logoDark} alt="sightfull" className="inline h-[27px]" />
          </a>

          <div className="hidden lg:block ml-[22px] xl:ml-[82px]">
            {false && <NavigationMega items={links} dark={dark} />}
          </div>
        </div>

        <div className="hidden lg:block ">
          {true && (
            <div className="w-full flex justify-end space-x-4  lg:space-x-2 xl:space-x-3 items-center  ">
              
{/* 
              <div className=" font-semibold text-base xl:space-x-8 lg:space-x-4">
                <Header.ButtonV2
                  {...signIn}
                  className="text-[#3D434C]  sticky_topbar_signin border border-transparent   px-[22px] hover:bg-[#F7F9FA] dark:text-white dark:hover:bg-[#415167]"
                />
              </div> */}
              
              <div>
                <TryOut
                  title="Try it, Free"
                  className="text-base  font-medium py-3 px-[30px] rounded-lg leading-[20px] whitespace-nowrap bg-[#325FFF]  hover:bg-[#1B4DFF] text-white border border-[#4A72FF] hover:border-[#3D434C88] demo_account  "
                  // style={{
                  //   background: 'linear-gradient(286.05deg, #4A72FF 6.93%, #1B4DFF 67.1%)'

                  // }}
                  check={false}
                />
              </div>

              <div className="lg:z-30">
                <Header.ButtonV2
                  {...cta}
                  className="text-[#3D434C] bg-white  border border-[#DEE1E5] dark:border-[#DEE1E588] hover:bg-[#F7F9FA]  sticky_topbar_demo inline-block   px-4"
                />
              </div>
              
            </div>
          )}
        </div>

        {/* {showMenus && (
          <div className="block lg:hidden relative print:hidden">
            <div className="w-full flex justify-end">
              <button onClick={() => setIsOpen(!isOpen)} className="h-10">
                <img alt="menu trigger" src={isOpen ? closeImg : menuImg} />
              </button>
            </div>
          </div>
        )} */}
      </div>

      {/* {showMenus && (
        <MenuMobile
          isOpen={isOpen}
          links={links}
          toggleSubMenu={toggleSubMenu}
          subMenuId={subMenuId}
          //marketplace={marketplace}
          cta={cta}
          //isMarketplace={isMarketplace}
        />
      )} */}
    </header>
  );
};

const Header = () => {}
Header.Button = ({ label, url, className, ...props }) => {
  const external = url.startsWith("https://");
  const baseClassName =
    "text-base font-medium pt-2.5 pb-[13px] px-5 rounded-[44px] leading-[20px] whitespace-nowrap ";

  return external ? (
    <a href={url} className={baseClassName + className} {...props}>
      {label}
    </a>
  ) : (
    <Link to={url} className={baseClassName + className} {...props}>
      {label}
    </Link>
  );
};

Header.ButtonV2 = ({ label, url, className, ...props }) => {
  const external = url.startsWith("https://");
  const baseClassName =
    "text-base font-medium py-3  rounded-lg leading-[20px] whitespace-nowrap ";

  return external ? (
    <a href={url} className={baseClassName + className} {...props}>
      {label}
    </a>
  ) : (
    <Link to={url} className={baseClassName + className} {...props}>
      {label}
    </Link>
  );
};


const MenuMobile = ({
  isOpen,
  links,
  toggleSubMenu,
  subMenuId,
  marketplace,
  cta,
  isMarketplace,
}) => {
  return (
    <div
      className={
        "bg-white dark:bg-black  h-screen w-full outline-none overflow-x-hidden overflow-y-auto p-8 text-sm h-full top-[70px]  " +
        (isOpen ? "fixed" : "hidden")
      }
    >
      {links.map((item, i) => (
        <div className="pb-6" key={i}>
          <div className="uppercase flex">
            {item?.groups.length === 0 && (
              <Link to={item.url}>{item.label}</Link>
            )}
            {item?.groups.length > 0 && (
              <button
                className="uppercase"
                onClick={() => toggleSubMenu(i + 1)}
              >
                {item.label}{" "}
                <img
                  src={arrow}
                  alt="arrow "
                  className={
                    "inline my-auto ml-2 duration-500 " +
                    (subMenuId === i + 1 ? " rotate-180 " : "")
                  }
                />
              </button>
            )}
          </div>

          <div
            className={
              "ml-4 my-1 space-y-2 " +
              (subMenuId === i + 1 ? " block " : " hidden ")
            }
          >
            {item.url === "/platform/" && (
              <div className="py-4 px-4 -ml-4 my-4 bg-blue normal-case text-left">
                <Link to={item.url} className=" text-left ">
                  <div className="space-y-1 flex flex-row justify-start space-x-2 text-white items-center">
                    <div className="p-1">
                      <img src={platformIcon} alt="platform" />
                    </div>
                    <div>
                      <div className=" font-medium">
                        Platform Overview{" "}
                        <img
                          src={platformArrow}
                          alt="arrow"
                          className="inline "
                        />
                      </div>
                      <div className="">Features & capabilities</div>
                    </div>
                  </div>
                </Link>
              </div>
            )}
            {item.groups.map((group, j) => (
              <div
                key={j}
                className={`${
                  j === 1 && item.label === "Platform" ? "hidden border-8" : ""
                }`}
              >
                {group.label && <div className="pt-2 ">{group.label}</div>}
                {group.children.map((e, k) => (
                  <div key={k} className="fade py-1 flex flex-row">
                    {e.image && (
                      <div className="inline mr-4">
                        <img
                          className="inline"
                          src={e.image.url}
                          alt={e.image.alt}
                        />
                      </div>
                    )}
                    <div>
                      <div>
                        <MenuMobile.Button {...e} />
                      </div>
                      {e.description && (
                        <div className="normal-case text-[#8F90A6] ">
                          {e.description}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className="py-6">
        <Link
          to={cta.url}
          className="bg-blue text-white text-sm font-bold border rounded-full py-3 px-8 whitespace-nowrap sticky_topbar_demo"
        >
          {cta.label}
        </Link>
      </div>
    </div>
  );
};

MenuMobile.Button = ({ label, url, className, ...props }) => {
  const external = url.startsWith("https://");

  return external ? (
    <a href={url} className={className} {...props}>
      {label}
    </a>
  ) : (
    <Link to={url} className={className} {...props}>
      {label}
    </Link>
  );
};

export default HeaderMini;
