import React from "react";
import ParagraphZebraCard from "./zebra-card1";

const ParagraphZebra1 = ({
  data,
  context,
  className,
  classNameCards,
  ...props
}) => {
  
  if ( data.relationships.cards.length === 0  ) return false;
  //console.log(className,data,classNameCards)
  
  
  return (
    <div className={className}>
      
      <div className="md:hidden grid grid-cols-1 gap-4">
        {data.relationships.cards.map((c, index) => (
          <ParagraphZebraCard data={c} index={index} key={index} />
        ))}
      </div>
      <div
        className={
          "hidden md:block gap-4 md:gap-[80px] " +
          classNameCards
        }
      >
        {data.relationships.cards.map((c, index) => (
          <ParagraphZebraCard data={c} index={index+1} key={index} context={context} />
        ))}
      </div>
    </div>
  );
};

export default ParagraphZebra1;
