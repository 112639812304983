import React from "react";
import { Link } from "gatsby";
import { pipesToSpan } from "../../util/common";
import TryOut from "../buttons/tryout";

const ParagraphZebraCard1 = ({ data, context, ...props }) => {
  //console.log(data);

  const image = {
    alt: data?.image?.alt,
    src: data?.relationships?.image?.localFile?.publicURL,
  };

  const description = data?.description?.processed;

  const { index } = props;
  const even = index % 2 === 0;

  //console.log(image);

  const elementA = (
    <div className=" md:my-0 space-y-[20px] lg:max-w-[510px]">
      <div className="space-y-[12px]">
      <div className="flex items-center space-x-2 "  >
        <img src={data.relationships?.icon?.localFile?.publicURL} alt={data?.icon?.alt} />
        <span className="text-[#325FFF] text-xl">
            {data.tag}
        </span>
      </div>
      <h3
        className="font-regular text-[32px] md:text-[36px] leading-[36px] md:leading-[40px] tracking-[-0.03em] text-[#3D434C] "
        dangerouslySetInnerHTML={{ __html: data.title }}
      />
</div>

      <div
        className="text-lg leading-[24px] md:leading-[27px] tracking-[-0.01em] text-[#3D434C] space-y-5 font-light "
        dangerouslySetInnerHTML={{ __html: description }}
      />

      {/* links here  */}
    </div>
  );

  const elementB = (
    <div className="">
      {image.src && (
        <div className="">
          <img
            src={`${image.src}`}
            alt={`${image.alt}`}
            className="rounded-[20px]"
          />
        </div>
      )}
    </div>
  );


  return (
    <div className="py-4 lg:p-[30px] text-[#3D434C] w-full space-y-[30px] slidein">
      <div
        className={
          "flex lg:flex-row gap-1 lg:gap-20 justify-between items-center " +
          (even ? "even flex-col" : "odd flex-col-reverse")
        }
      >
        <div className="md:w-1/2 ">
          {even && elementB}
          {!even && elementA}
        </div>
        <div className="md:w-1/2">
          {even && elementA}
          {!even && elementB}
        </div>
      </div>
    </div>
  );
};

const ParagraphZebraCardSmall1 = ({ data, context, ...props }) => {
  return (
    <div className="container  space-y-[30px]">
      <div className="text-center">
        <h3
          className="text-[60px] leading-[66px] font-normal"
          dangerouslySetInnerHTML={{
            __html: pipesToSpan(data.title, "font-semibold").replace(
              "  ",
              "<br/>"
            ),
          }}
        />
      </div>
      <div className="flex flex-row gap-3 justify-center">
        {data.links.map((item, inx) => (
          <ButtonOrLink key={inx} title={item.title} uri={item.uri.replace("internal:/", "/")} 
          className={
            "py-3 px-8 border border-[#DEE1E5] rounded-[8px] font-medium leading-[20px] " +
            (inx === 0 ? "text-white bg-blue" : "text-[#3D434C]")
          }
          />
        ))}
      </div>
    </div>
  );
};


const ButtonOrLink = ({ title, uri, className }) => {
  if (uri === "/tryout" || uri === "/tryout/" ) {
    return <TryOut className={className} check={false} title={title} />;
  }

  return (
    <Link to={uri} className={className}>
      {title}
    </Link>
  );
};




export default ParagraphZebraCard1;
export { ParagraphZebraCardSmall1 };
