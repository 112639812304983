import React from "react";
import HubspotForm from "react-hubspot-form";


const ParagraphHubspot3 = ({ data, children }) => {

  const hubspot = data

  const ready = (form0) => {

    //const form = form0.parentElement

    const submit = document.getElementsByClassName("hs-submit")[0]
    const legal = document.getElementsByClassName("legal-consent-container")[0];
    const news = document.getElementsByClassName("hs-receive_news")[0].parentElement;
    const last = document.getElementsByClassName("hs-message")[0].parentElement 

    let d2 = document.createElement("div");
    d2.className = "flex flex-row justify-between";
    d2.appendChild(legal)
    d2.appendChild(submit)
    last.after(d2)
    last.after(news)
    
  };

  return (
    <div className="container mx-auto py-[55px]     ">
      <div className="flex flex-col md:flex-row justify-between xl:mr-[140px]  xl:ml-[126px] lg:mx-[24px] items-center">
        <div className="md:w-1/2 ">
          {children}
        </div>
        <div className="md:w-[400px] pt-6 md:pt-0 md:ml-[3%] lg:ml-0 backdrop-blur-sm">
          <div className=" p-7 bg-[#FFFFFF33] rounded-[12px] lp-use-case-hubspot">
            <div className="text-center text-white text-[1.4rem] leading-[27px] font-light mb-5">
              {hubspot.title}
            </div>
            <HubspotForm
              portalId={hubspot.portal_id}
              formId={hubspot.form_id}
              //cssRequired=""
              css=" " // !important
              cssClass="sigthfull-hubspot sigthfull-hubspot-3"
              submitButtonClass=" bg-[#325FFF] py-2 px-5 rounded-lg text-white text-sm fomt-medium leading-[20px]"
              errorClass="sigthfull-hubspot-error"
              errorMessageClass="sigthfull-hubspot-error-message"
              //onSubmit={(e) => console.log('Submit!')}
              //onFromSubmit={(e) => console.log('on form submit')}
              //onFormSubmitted={(e) => console.log('on onFormSubmitted')}
              onReady={ready}
              //loading={<div>Loading...</div>}
              //redirectUrl={path + "/thank-you"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default ParagraphHubspot3;
