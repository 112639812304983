import React from "react";
import { Link } from "gatsby";

const BlockContentLogos = ({ data, context, ...props }) => {
  const images = data?.relationships?.images
    ? data?.relationships?.images.map((item, index) => ({
        src: item?.localFile?.publicURL,
        alt: data?.images[index]?.alt,
        width: data?.images[index]?.width,
      }))
    : [];

  const { title, description, buttons } = data;
  const { className } = props;

  return (
    <div className={className}>
      <h2
        className="text-[#3D434C] font-normal text-[40px]  md:text-[60px] md:leading-[72px] leadin-[42px] tracking-[-0.03em] text-left"
        dangerouslySetInnerHTML={{ __html: title }} />

      {description && (
        <div className="text-lg max-w-[870px] leading-[27px] tracking-[-0.01em] mx-auto text-[#3D434C]">
          {description}
        </div>
      )}
      <div className="flex flex-row justify-around flex-wrap gap-10 md:gap-1">
        {images.map((item, index) => (
          <img src={item.src} alt={item.alt} key={index} />
        ))}
      </div>
      {buttons.length > 0 && (
        <div>
          {buttons.map((b, index) => (
            <Button {...b} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

const Button = ({ title, index, uri, ...props }) => {
  const url = uri.replace("internal:/", "/");

  return (
    <Link
      to={url}
      className=" text-lg font-medium leading-[20px]  text-[#4A72FF]"
    >
      {title}
    </Link>
  );
};

export default BlockContentLogos;
